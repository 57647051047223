import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import React, { useContext, useState } from "react";
import loginBackground from "../../assets/images/bg/loginBg.png";
import FEDLoginBackground from "../../assets/images/bg/FEDLoginBg.png";
import classes from "./loginPage.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { LOGIN } from "../../constants/endpoints";
import {
  setAccessTokenCookie,
  setEmailVerifiedCookie,
  setMobileVerifiedCookie,
  setRefreshCookie,
} from "../../utils/cookie";
import { useNavigate } from "react-router-dom";
import CircleIcon from "../dashboard/components/CircleIcon";
import { successToaster } from "../../helpers/toasterConfiguration";
import ForgetPassDialog from "../../components/forgetPassDialog";
import ResetPassDialog from "../../components/ResetPassDialog";
import environment from "../../environment";
import UserContext from "../../context/UserContext";
import GoogleRecaptcha from "../../components/common/GoogleRecapcha";
import encryptMessage from "../../utils/encryptMessage";
import { passwordRegex } from "../../constants/types";
import { typesOfProjects } from "../../constants/enums";

const LoginPage = () => {
  const isFED = process.env.REACT_APP_ACTIVE_ENV == typesOfProjects.FED;
  const theme = useTheme();
  const mediaQueryMd = useMediaQuery(theme.breakpoints.down("768"));
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [fireValidation, setFireValidation] = useState(false);
  const navigate = useNavigate();

  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  // captcha variables
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaExpired, setCaptchaExpired] = useState(false);
  //
  const { setUserProfile } = useContext(UserContext);
  // captcha
  function getCaptchaInfo(val) {
    setCaptchaValue(val.value);
    setCaptchaExpired(val.expired);
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("يرجى ادخال البريد الالكتروني")
      .email("صيغة البريد الالكتروني غير صحيحة"),
    password: yup
      .string()
      .matches(
        passwordRegex,
        "يجب أن تحتوي كلمة المرور على الأقل حرف كبير، وحرف صغير، ورقم واحد، وأحد الرموز الخاصة (@، #، $، %، ^، &، +، =)"
      )
      .required("يرجى ادخال كلمة المرور"),
  });

  // Function to handle form submission
  const handleSubmit = (values) => {
    if (!captchaExpired && captchaValue !== "") {
      setLoading(true);
      let encryptPass = encryptMessage(values.password, environment.PUBKEY);
      const params = {
        Email: values.email,
        Password: encryptPass,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Version": "2",
        },
      };
      axios
        .get(LOGIN, { params, ...config })
        .then((res) => {
          setErrorMessage("");
          setShowError(false);
          localStorage.setItem("email", values.email);
          localStorage.setItem(environment.TOKEN_KEY, res?.data?.Token);
          setAccessTokenCookie(res.data.Token);
          setEmailVerifiedCookie(res.data.EmailVerified);
          setMobileVerifiedCookie(res.data.MobileVerified);
          setRefreshCookie(res.data.RefreshToken);
          successToaster("تم تسجيل الدخول بنجاح");
          const redirectURL = JSON.parse(
            localStorage.getItem(environment.redirectURL)
          );
          if (redirectURL) {
            setTimeout(() => {
              navigate(`${redirectURL.pathname}${redirectURL.search}`, {
                replace: true,
              });
            }, 1000);
            setUserProfile("");
            localStorage.removeItem(environment.redirectURL);
          } else {
            if (res.data.Token !== "" && res.data.Token) {
              setTimeout(() => {
                navigate("/dashboard/in-progress", { replace: true });
              }, 1000);
            } else {
              setTimeout(() => {
                navigate("/confirm-account", { replace: true });
              }, 1000);
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data);
          setShowError(true);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Grid
      container
      sx={{
        height: mediaQueryMd ? "90vh" : "80vh",
        paddingTop: mediaQueryMd ? "8rem" : "",
      }}
    >
      <Grid item xs={mediaQueryMd ? 12 : 6}>
        <Stack
          sx={{
            flex: 1,
            height: "100%",
            padding: { xs: "0 1rem", sm: "0 1rem", md: "0 5rem" },
            // padding: "",
          }}
          spacing={3}
          justifyContent={"center"}
        >
          <Typography
            variant="h6"
            textAlign={"center"}
            className={classes.loginText}
          >
            <CircleIcon size="10" />
            <CircleIcon size="10" />
            تسجيل الدخول
          </Typography>
          <Typography
            variant="P"
            textAlign={"center"}
            className={classes.loginSubText}
          >
            باستخدام البريد الالكتروني
          </Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            validateOnChange={fireValidation}
            validateOnBlur={fireValidation}
            onSubmit={handleSubmit}
          >
            {(errors) => (
              <Form>
                <Stack spacing={2}>
                  {showError && (
                    <Typography className={classes.errorMessgae} variant="p">
                      {errorMessage}
                    </Typography>
                  )}
                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.email ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="email"
                    name="email"
                    placeholder={"البريد الاكتروني"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />

                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.password ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="password"
                    name="password"
                    placeholder={"كلمة المرور"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        className={classes.errorClass}
                        name="password"
                        render={(msg) => (
                          <div
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />

                  <Box
                    className={`${classes.marginTopZero} ${classes.forgetPassword}`}
                  >
                    <ForgetPassDialog
                      setOpenResetDialog={setOpenResetDialog}
                      setUserEmail={setUserEmail}
                    />
                    <ResetPassDialog
                      open={openResetDialog}
                      setOpen={setOpenResetDialog}
                      userEmail={userEmail}
                    />
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <GoogleRecaptcha getCaptchaInfo={getCaptchaInfo} />
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      // onClick={handleSignIn}
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      className={classes.loginButton}
                      onClick={() => setFireValidation(true)}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "تسجيل الدخول"
                      )}
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
          {/* <TextField placeholder="البريد الالكتروني" variant="outlined" />
          <TextField placeholder="كلمة المرور" variant="outlined" /> */}
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Link
              to={"/register"}
              style={{ textAlign: "center" }}
              className={`${classes.greenColor} ${classes.registerText}`}
            >
              لا تملك حساباً بعد؟ <strong>سجل الآن</strong>
            </Link>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: mediaQueryMd ? "none" : "",
          height: "100%",
          backgroundImage: `url(${
            isFED ? FEDLoginBackground : loginBackground
          })`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%", flex: 1 }}
        >
          <Typography
            variant="h4"
            className={`${classes.imageText} ${classes.welcomeText}`}
          >
            مرحبا بكم في موقع وزارة الاقتصاد و التجارة اللبنانية
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
