import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import classes from "./registerPage.module.css";
import useState from "react-usestateref"; // see this line
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import Step1 from "./steps/step 1";
import PersonalInformation from "./steps/step2";
import {
  CompanyAccountValidation,
  personalAccountValidation,
} from "./steps/step2/step2Validation";
import AddressStep from "./steps/step3";
import { AddressStepValidation } from "./steps/step3/adressStepValidation";
import Step4CheckBox from "./steps/step4";
import ApplicantInformation from "./steps/step5";
import { ApplicantInformationValidation } from "./steps/step5/applicantValidation";
import AttachmentStep from "./steps/step6";
import {
  CompanyAttachmentsValidation,
  PersonalAttachmentsValidation,
} from "./steps/step6/attachmentValidation";
import RegistrationInfosStep from "./steps/step7";
import { RegistrationInformationValidation } from "./steps/step7/validation";
import FinalStep from "./steps/step8";
import axios from "axios";
import { GetAllCountries, Register } from "../../constants/endpoints";
import {
  getAgesOptions,
  getCitiesOptions,
  getCompanyTypeOptions,
  getCountriesOptions,
  getDistrictsOptions,
  getFeminineCompanyOptions,
  getGovernorateOptions,
  getTTypeOptions,
  getTradeTypeOptions,
} from "../../services/register/picklists.services";
import { useLocation } from "react-router-dom";
import { TroubleshootOutlined } from "@mui/icons-material";
import {
  errorToaster,
  successToaster,
} from "../../helpers/toasterConfiguration";
import environment from "../../environment";
import encryptMessage from "../../utils/encryptMessage";

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  // "Step 7",
  // "Step 8",
]; // Replace with your step labels

const RegisterPage = () => {
  const theme = useTheme();
  const mediaQueryMd = useMediaQuery(theme.breakpoints.down("768"));
  const [activeStep, setActiveStep] = useState(0);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(false);
  /* person account info */
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError, usernameErrorRef] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError, middleNameErrorRef] =
    useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError, lastNameErrorRef] = useState(false);
  const [cardId, setCardId] = useState("");
  const [cardIdError, setCardIdError, cardIdErrorRef] = useState(false);
  const [nationality, setNationality] = useState("");
  const [nationalityError, setNationalityError, nationalityErrorRef] =
    useState(false);
  const [age, setAge] = useState("");
  const [ageError, setAgeError, ageErrorRef] = useState(false);
  const [specialNeed, setSpecialNeed] = useState(false);
  const [selectedSex, setSelectedSex] = useState("M");
  const [selectedSexError, setSelectedSexError, selectedSexErrorRef] =
    useState(false);

  /* company account info */
  const [legalName, setLegalName] = useState("");
  const [legalNameError, setLegalNameError, legalNameErrorRef] =
    useState(false);
  const [companyAccountType, setCompanyAccountType] = useState("");
  const [
    companyAccountTypeError,
    setCompanyAccountTypeError,
    companyAccountTypeErrorRef,
  ] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [companyTypeError, setCompanyTypeError, companyTypeErrorRef] =
    useState(false);
  const [businessType, setBusinessType] = useState("");
  const [businessTypeError, setBusinessTypeError, businessTypeErrorRef] =
    useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessNameError, setBusinessNameError, businessNameErrorRef] =
    useState(false);
  const [financialNumber, setFinancialNumber] = useState("");
  const [
    financialNumberError,
    setFinancialNumberError,
    financialNumberErrorRef,
  ] = useState(false);
  const [feminiseCompanyCheckbox, setFeminiseCompanyCheckbox] = useState(false);
  const [feminiseCompanySelect, setFeminiseCompanySelect] = useState("");
  const [
    feminiseCompanySelectError,
    setFeminiseCompanySelectError,
    feminiseCompanySelectErrorRef,
  ] = useState("");
  const [authorizedSignatoryName, setAuthorizedSignatoryName] = useState("");
  const [
    authorizedSignatoryNameError,
    setAuthorizedSignatoryNameError,
    authorizedSignatoryNameErrorRef,
  ] = useState(false);
  const [personType, setPersonType] = useState("");
  const [personTypeError, setPersonTypeError, personTypeErrorRef] =
    useState(false);
  const [authorizedSignatoryPhoneNumber, setAuthorizedSignatoryPhoneNumber] =
    useState("");
  const [
    authorizedSignatoryPhoneNumberError,
    setAuthorizedSignatoryPhoneNumberError,
    authorizedSignatoryPhoneNumberErrorRef,
  ] = useState(false);
  const [authorizedSignatoryNationality, setAuthorizedSignatoryNationality] =
    useState("");
  const [
    authorizedSignatoryNationalityError,
    setAuthorizedSignatoryNationalityError,
    authorizedSignatoryNationalityErrorRef,
  ] = useState(false);
  /*  ADDRESS INFORMATION */
  const [governorate, setGovernorate] = useState("");
  const [governorateError, setGovernorateError, governorateErrorRef] =
    useState(false);

  const [street, setStreet] = useState("");
  const [streetError, setStreetError, streetErrorRef] = useState(false);

  const [judiciary, setJudiciary] = useState("");
  const [judiciaryError, setJudiciaryError, judiciaryErrorRef] =
    useState(false);

  const [building, setBuilding] = useState("");
  const [buildingError, setBuildingError, buildingErrorRef] = useState(false);

  const [town, setTown] = useState("");
  const [townError, setTownError, townErrorRef] = useState(false);

  const [floor, setFloor] = useState("");
  const [floorError, setFloorError, floorErrorRef] = useState(false);

  const [POBox, setPOBox] = useState("");
  const [POBoxError, setPOBoxError, POBoxErrorRef] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError, phoneNumberErrorRef] =
    useState(false);

  /* Step 4 checkBox */
  const [step4check, setStep4check] = useState(false);

  /* Step 5 */

  const [applicantName, setApplicantName] = useState("");
  const [applicantNameError, setApplicantNameError, applicantNameErrorRef] =
    useState(false);

  const [applicantMiddleName, setApplicantMiddleName] = useState("");
  const [
    applicantMiddleNameError,
    setApplicantMiddleNameError,
    applicantMiddleNameErrorRef,
  ] = useState(false);

  const [applicantLastName, setApplicantLastName] = useState("");
  const [
    applicantLastNameError,
    setApplicantLastNameError,
    applicantLastNameErrorRef,
  ] = useState(false);

  const [applicantCardId, setApplicantCardId] = useState("");
  const [
    applicantCardIdError,
    setApplicantCardIdError,
    applicantCardIdErrorRef,
  ] = useState(false);

  /* Attachment Step */
  /* person account attachments */

  /* المفوض بالتوقيع / صاحب العلاقة */
  const [personConcernedIdentity, setPersonConcernedIdentity] = useState(null);
  const [personConcernedIdentityName, setPersonConcernedIdentityName] =
    useState(null);
  const [
    personConcernedIdentityError,
    setPersonConcernedIdentityError,
    personConcernedIdentityErrorRef,
  ] = useState(false);

  /* مقدم الطلب */
  const [applicantIdentity, setApplicantIdentity] = useState(null);
  const [applicantIdentityName, setApplicantIdentityName] = useState(null);
  const [
    applicantIdentityError,
    setApplicantIdentityError,
    applicantIdentityErrorRef,
  ] = useState(false);

  /* وكالة التفويض */
  const [authorizationPhoto, setAuthorizationPhoto] = useState(null);
  const [authorizationPhotoName, setAuthorizationPhotoName] = useState(null);

  const [
    authorizationPhotoError,
    setAuthorizationPhotoError,
    authorizationPhotoErrorRef,
  ] = useState(false);

  /* company account attachments */
  const [commercialPhoto, setCommercialPhoto] = useState(null);
  const [commercialPhotoName, setCommercialPhotoName] = useState(null);
  const [
    commercialPhotoError,
    setCommercialPhotoError,
    commercialPhotoErrorRef,
  ] = useState(false);

  const [commercialRegisterPhoto, setCommercialRegisterPhoto] = useState(null);
  const [commercialRegisterPhotoName, setCommercialRegisterPhotoName] =
    useState(null);
  const [
    commercialRegisterPhotoError,
    setCommercialRegisterPhotoError,
    commercialRegisterPhotoErrorRef,
  ] = useState(false);

  // *****************
  // هويات الورثة
  const [IdentitiesOfHeirs, setIdentitiesOfHeirs] = useState(null);
  const [IdentitiesOfHeirsName, setIdentitiesOfHeirsName] = useState(null);
  const [
    IdentitiesOfHeirsError,
    setIdentitiesOfHeirsError,
    IdentitiesOfHeirsErrorRef,
  ] = useState(false);

  // حصر إرث
  const [InventoryOfInheritance, setInventoryOfInheritance] = useState(null);
  const [InventoryOfInheritanceName, setInventoryOfInheritanceName] =
    useState(null);
  const [
    InventoryOfInheritanceError,
    setInventoryOfInheritanceError,
    InventoryOfInheritanceErrorRef,
  ] = useState(false);
  // حكم قضائي
  const [JudicialRulings, setJudicialRulings] = useState(null);
  const [JudicialRulingsName, setJudicialRulingsName] = useState(null);
  const [
    JudicialRulingsError,
    setJudicialRulingsError,
    JudicialRulingsErrorRef,
  ] = useState(false);

  // صورة الهوية الخلفية
  const [OwnerIdentityBack, setOwnerIdentityBack] = useState(null);
  const [OwnerIdentityBackName, setOwnerIdentityBackName] = useState(null);
  const [
    OwnerIdentityBackError,
    setOwnerIdentityBackError,
    OwnerIdentityBackErrorRef,
  ] = useState(false);
  // صورة عن وكالة المفوض بالتوقيع
  const [AuthorizedSignatoryIdentity, setAuthorizedSignatoryIdentity] =
    useState(null);
  const [AuthorizedSignatoryIdentityName, setAuthorizedSignatoryIdentityName] =
    useState(null);
  const [
    AuthorizedSignatoryIdentityError,
    setAuthorizedSignatoryIdentityError,
    AuthorizedSignatoryIdentityErrorRef,
  ] = useState(false);

  /* Registration Information */
  const [email, setEmail] = useState("");
  const [emailError, setEmailError, emailErrorRef] = useState(false);

  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [
    registrationPhoneNumberError,
    setRegistrationPhoneNumberError,
    registrationPhoneNumberErrorRef,
  ] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError, passwordErrorRef] = useState(false);

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [
    passwordConfirmationError,
    setPasswordConfirmationError,
    passwordConfirmationErrorRef,
  ] = useState(false);

  // captcha variables
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaExpired, setCaptchaExpired] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextStep4 = () => {
    step4check
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handleBackStep4 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // step4check
    //   ? setActiveStep((prevActiveStep) => prevActiveStep - 1)
    //   : setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepTwo = (infos) => {
    if (checked1) {
      personalAccountValidation(
        infos.personAccount,
        setUsernameError,
        setMiddleNameError,
        setLastNameError,
        setCardIdError,
        setNationalityError,
        setAgeError,
        setSelectedSexError
      );

      if (
        usernameErrorRef.current ||
        middleNameErrorRef.current ||
        lastNameErrorRef.current ||
        cardIdErrorRef.current ||
        nationalityErrorRef.current ||
        ageErrorRef.current ||
        selectedSexErrorRef.current
      ) {
        return;
      } else handleNext();
    } else {
      CompanyAccountValidation(
        infos.companyAccount,
        setLegalNameError,
        setCompanyAccountTypeError,
        setCompanyTypeError,
        setBusinessTypeError,
        setBusinessNameError,
        setFinancialNumberError,
        setAuthorizedSignatoryNameError,
        setPersonTypeError,
        setAuthorizedSignatoryPhoneNumberError,
        setAuthorizedSignatoryNationalityError,
        setFeminiseCompanySelectError
      );
      if (
        legalNameErrorRef.current ||
        companyAccountTypeErrorRef.current ||
        companyTypeErrorRef.current ||
        businessTypeErrorRef.current ||
        businessNameErrorRef.current ||
        financialNumberErrorRef.current ||
        authorizedSignatoryNameErrorRef.current ||
        personTypeErrorRef.current ||
        authorizedSignatoryPhoneNumberErrorRef.current ||
        authorizedSignatoryNationalityErrorRef.current ||
        feminiseCompanySelectErrorRef.current
      ) {
        return;
      } else handleNext();
    }
  };

  const handleStepThree = (infos) => {
    AddressStepValidation(
      infos,
      setGovernorateError,
      setStreetError,
      setJudiciaryError,
      setBuildingError,
      setTownError,
      setFloorError,
      setPOBoxError,
      setPhoneNumberError
    );

    if (
      governorateErrorRef.current ||
      streetErrorRef.current ||
      judiciaryErrorRef.current ||
      buildingErrorRef.current ||
      townErrorRef.current ||
      floorErrorRef.current ||
      POBoxErrorRef.current ||
      phoneNumberErrorRef.current
    ) {
      return;
    } else handleNext();
  };

  const handleStepFive = (infos) => {
    ApplicantInformationValidation(
      infos,
      setApplicantNameError,
      setApplicantMiddleNameError,
      setApplicantLastNameError,
      setApplicantCardIdError
    );
    if (
      applicantNameErrorRef.current ||
      applicantMiddleNameErrorRef.current ||
      applicantLastNameErrorRef.current ||
      applicantCardIdErrorRef.current
    ) {
      return;
    } else handleNext();
  };

  const handleAttachmentStep = (infos) => {
    if (checked1) {
      if (step4check) {
        PersonalAttachmentsValidation(
          infos.personAccount,
          setPersonConcernedIdentityError,
          setApplicantIdentityError,
          setAuthorizationPhotoError
        );
      } else {
        PersonalAttachmentsValidation(
          infos.personAccount,
          setPersonConcernedIdentityError,
          () => {},
          () => {}
        );
      }

      if (
        personConcernedIdentityErrorRef.current ||
        applicantIdentityErrorRef.current ||
        authorizationPhotoErrorRef.current
      ) {
        return;
      } else handleNext();
    } else {
      if (step4check) {
        CompanyAttachmentsValidation(
          infos.companyAccount,
          setPersonConcernedIdentityError,
          setApplicantIdentityError,
          setAuthorizationPhotoError,
          setCommercialPhotoError,
          setCommercialRegisterPhotoError
        );
      } else {
        CompanyAttachmentsValidation(
          infos.companyAccount,
          setPersonConcernedIdentityError,
          () => {},
          () => {},
          setCommercialPhotoError,
          setCommercialRegisterPhotoError
        );
      }
      if (
        personConcernedIdentityErrorRef.current ||
        applicantIdentityErrorRef.current ||
        authorizationPhotoErrorRef.current ||
        commercialPhotoErrorRef.current ||
        commercialRegisterPhotoErrorRef.current
      ) {
        return;
      } else handleNext();
    }
  };
  const [loadingRegistration, setLoadingRegistration] = useState(false);
  const handleRegistrationInfo = (infos) => {
    RegistrationInformationValidation(
      infos,
      setEmailError,
      setPasswordError,
      setPasswordConfirmationError,
      setRegistrationPhoneNumberError
    );

    if (
      emailErrorRef.current ||
      passwordErrorRef.current ||
      passwordConfirmationErrorRef.current ||
      registrationPhoneNumberErrorRef.current
    ) {
      return;
    } else if (!captchaExpired && captchaValue !== "") {
      setLoadingRegistration(true);
      document.body.style.cursor = "wait";
      const data = {
        AccountTypeId: checked1 ? 1 : 2,
        LastName: lastName,
        FirstName: checked1 ? username : authorizedSignatoryName,
        MiddleName: middleName,
        IdentityNo: cardId,
        NationalityId: checked1 ? nationality : authorizedSignatoryNationality,
        Age: checked1 ? age : null,
        Gender: selectedSex,
        IsHandicapped: specialNeed,
        GovernorateId: governorate,
        DistrictId: judiciary,
        CityId: town,
        PostalCode: POBox,
        Street: street,
        Building: building,
        Floor: floor,
        PhoneNo: "+961" + authorizedSignatoryPhoneNumber.split(" ").join(""),
        IsDifferentApplicant: step4check,
        ApplicantLastName: applicantLastName,
        ApplicantFirstName: applicantName,
        ApplicantMiddleName: applicantMiddleName,
        ApplicantIdentityNo: applicantCardId,
        MobileNo: "+961" + registrationPhoneNumber.split(" ").join(""),
        LegalName: checked1 ? null : legalName,
        TypeId: companyAccountType === "" ? 1 : companyAccountType,
        CompanyTypeId: companyType === "" ? 1 : companyType,
        TradeTypeId: checked1 ? null : businessType,
        TradeName: checked1 ? null : businessName,
        FinancialFigure: checked1 ? null : financialNumber,
        IsFeminineCompany: feminiseCompanyCheckbox,
        FeminineCompanyTypeId: checked1
          ? null
          : feminiseCompanyCheckbox
          ? feminiseCompanySelect
          : null,
        // صورة الهوية الأمامية
        OwnerIdentity: personConcernedIdentity,
        OwnerIdentityName: personConcernedIdentityName,
        ApplicantIdentity: applicantIdentity,
        ApplicantIdentityName: applicantIdentityName,
        Authorization: authorizationPhoto,
        AuthorizationName: authorizationPhotoName,
        CommercialAnnouncement: commercialPhoto,
        CommercialAnnouncementName: commercialPhotoName,
        CommercialRegister: commercialRegisterPhoto,
        CommercialRegisterName: commercialRegisterPhotoName,
        Title: personType,
        AddressMobileNo: "+961" + phoneNumber.split(" ").join(""),
        Email: email,
        Password: encryptMessage(password, environment.PUBKEY),
        PasswordConfirmation: encryptMessage(
          passwordConfirmation,
          environment.PUBKEY
        ),
        // هويات الورثة
        IdentitiesOfHeirs: IdentitiesOfHeirs,
        IdentitiesOfHeirsName: IdentitiesOfHeirsName,
        // حصر إرث
        InventoryOfInheritance: InventoryOfInheritance,
        InventoryOfInheritanceName: InventoryOfInheritanceName,
        // حكم قضائي
        JudicialRulings: JudicialRulings,
        JudicialRulingsName: JudicialRulingsName,
        // صورة الهوية الخلفية
        OwnerIdentityBack: OwnerIdentityBack,
        OwnerIdentityBackName: OwnerIdentityBackName,
        // صورة عن وكالة المفوض بالتوقيع
        AuthorizedSignatoryIdentity: AuthorizedSignatoryIdentity,
        AuthorizedSignatoryIdentityName: AuthorizedSignatoryIdentityName,
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Version": "3",
      };

      axios
        .post(Register, JSON.stringify(data), { headers })
        .then((response) => {
          if (response.status === 201) {
            successToaster("تم ارسال كود تحقق الى بريدك الالكتروني ورقم هاتفك");
          }
          handleNext();
        })
        .catch((error) => {
          // errorToaster("حدث خطأ ما يرجى اعادة المحاولة لاحقاً");
          errorToaster(
            error.response.data
              ? error.response.data
              : "حدث خطأ ما يرجى اعادة المحاولة لاحقاً"
          );
        })
        .finally(() => {
          document.body.style.cursor = "default";
          setLoadingRegistration(false);
        });
    }
  };

  const handleStep1Submit = () => {
    if (checked1 || checked2) {
      handleNext();
    } else {
      return;
    }
  };

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [governorateOptions, setGovernorateOptions] = useState([]);
  const [districtsOptions, setDistrictsOptions] = useState([]);
  const [districtsOptionsLoading, setDistrictsOptionsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [citiesOptionsLoading, setCitiesOptionsLoading] = useState(false);

  const [AgeOptions, setAgeOptions] = useState([]);
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [tTypeOptions, setTTypeOptions] = useState([]);
  const [tradeTypeOptions, setTradeTypeOptions] = useState([]);
  const [feminineCompanyOptions, setFeminineCompanyOptions] = useState([]);

  //
  const getCompanyType = async () => {
    const response = await getCompanyTypeOptions();
    if (response.status === 200) {
      setCompanyTypeOptions(response.data);
    }
  };
  const getTType = async () => {
    const response = await getTTypeOptions();
    if (response.status === 200) {
      setTTypeOptions(response.data);
    }
  };
  const getTradeType = async () => {
    const response = await getTradeTypeOptions();
    if (response.status === 200) {
      setTradeTypeOptions(response.data);
    }
  };
  const getFeminineCompany = async () => {
    const response = await getFeminineCompanyOptions();
    if (response.status === 200) {
      setFeminineCompanyOptions(response.data);
    }
  };
  const getCountries = async () => {
    const response = await getCountriesOptions();
    if (response.status === 200) {
      setCountriesOptions(response.data);
    }
  };

  const getAges = async () => {
    const response = await getAgesOptions();
    if (response.status === 200) {
      setAgeOptions(response.data);
    }
  };

  const getGovernorate = async () => {
    const response = await getGovernorateOptions();
    if (response.status === 200) {
      setGovernorateOptions(response.data);
    }
  };

  const getDistricts = async (id) => {
    setDistrictsOptionsLoading(true);
    setCitiesOptionsLoading(true);
    const response = await getDistrictsOptions(id);
    if (response.status === 200) {
      setDistrictsOptions(response.data);
      setDistrictsOptionsLoading(false);
      setCitiesOptionsLoading(false);
    }
  };

  const getCities = async (id) => {
    setCitiesOptionsLoading(true);
    const response = await getCitiesOptions(id);
    if (response.status === 200) {
      setCitiesOptions(response.data);
      setCitiesOptionsLoading(false);
    }
  };
  //
  useEffect(() => {
    getCountries();
    getGovernorate();
    getAges();
    getTType();
    getCompanyType();
    getFeminineCompany();
    getTradeType();
  }, []);

  useEffect(() => {
    setJudiciary("");
    setTown("");
    governorate !== "" && getDistricts(governorate);
  }, [governorate]);

  useEffect(() => {
    setTown("");
    judiciary !== "" && getCities(judiciary);
  }, [judiciary]);

  const location = useLocation();
  return (
    <Box className={classes.bg}>
      <Container
        maxWidth="lg"
        className={classes.pageContainer}
        sx={{ paddingTop: mediaQueryMd ? "8rem" : "" }}
      >
        {location.pathname !== "/dashboard/settings" && (
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Fragment>
          {activeStep === steps.length ? (
            <div>
              <p>All steps completed!</p>
              <Button onClick={() => setActiveStep(0)}>Reset</Button>
            </div>
          ) : (
            <Fragment>
              {activeStep === 0 && (
                <Step1
                  checked1={checked1}
                  checked2={checked2}
                  setChecked1={setChecked1}
                  setChecked2={setChecked2}
                  handleStep1Submit={handleStep1Submit}
                />
              )}
              {activeStep === 1 && (
                <PersonalInformation
                  feminineCompanyOptions={feminineCompanyOptions}
                  tTypeOptions={tTypeOptions}
                  tradeTypeOptions={tradeTypeOptions}
                  companyTypeOptions={companyTypeOptions}
                  handleBack={handleBack}
                  username={username}
                  setUsername={setUsername}
                  usernameError={usernameError}
                  middleName={middleName}
                  setMiddleName={setMiddleName}
                  middleNameError={middleNameError}
                  lastName={lastName}
                  setLastName={setLastName}
                  lastNameError={lastNameError}
                  cardId={cardId}
                  setCardId={setCardId}
                  cardIdError={cardIdError}
                  nationality={nationality}
                  setNationality={setNationality}
                  nationalityError={nationalityError}
                  age={age}
                  setAge={setAge}
                  ageError={ageError}
                  selectedSex={selectedSex}
                  setSelectedSex={setSelectedSex}
                  selectedSexError={selectedSexError}
                  specialNeed={specialNeed}
                  setSpecialNeed={setSpecialNeed}
                  checked1={checked1}
                  checked2={checked2}
                  legalName={legalName}
                  setLegalName={setLegalName}
                  legalNameError={legalNameError}
                  companyAccountType={companyAccountType}
                  setCompanyAccountType={setCompanyAccountType}
                  companyAccountTypeError={companyAccountTypeError}
                  companyType={companyType}
                  setCompanyType={setCompanyType}
                  companyTypeError={companyTypeError}
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  businessTypeError={businessTypeError}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  businessNameError={businessNameError}
                  financialNumber={financialNumber}
                  setFinancialNumber={setFinancialNumber}
                  financialNumberError={financialNumberError}
                  feminiseCompanyCheckbox={feminiseCompanyCheckbox}
                  setFeminiseCompanyCheckbox={setFeminiseCompanyCheckbox}
                  feminiseCompanySelectError={feminiseCompanySelectError}
                  feminiseCompanySelect={feminiseCompanySelect}
                  setFeminiseCompanySelect={setFeminiseCompanySelect}
                  authorizedSignatoryName={authorizedSignatoryName}
                  setAuthorizedSignatoryName={setAuthorizedSignatoryName}
                  authorizedSignatoryNameError={authorizedSignatoryNameError}
                  authorizedSignatoryPhoneNumber={
                    authorizedSignatoryPhoneNumber
                  }
                  setAuthorizedSignatoryPhoneNumber={
                    setAuthorizedSignatoryPhoneNumber
                  }
                  authorizedSignatoryPhoneNumberError={
                    authorizedSignatoryPhoneNumberError
                  }
                  setAuthorizedSignatoryPhoneNumberError={
                    setAuthorizedSignatoryPhoneNumberError
                  }
                  personType={personType}
                  setPersonType={setPersonType}
                  personTypeError={personTypeError}
                  authorizedSignatoryNationality={
                    authorizedSignatoryNationality
                  }
                  setAuthorizedSignatoryNationality={
                    setAuthorizedSignatoryNationality
                  }
                  authorizedSignatoryNationalityError={
                    authorizedSignatoryNationalityError
                  }
                  AgeOptions={AgeOptions}
                  handleNext={handleStepTwo}
                  nationalityOptions={countriesOptions}
                />
              )}

              {activeStep === 2 && (
                <AddressStep
                  citiesOptionsLoading={citiesOptionsLoading}
                  districtsOptionsLoading={districtsOptionsLoading}
                  governorateOptions={governorateOptions}
                  districtsOptions={districtsOptions}
                  citiesOptions={citiesOptions}
                  handleStepThree={handleStepThree}
                  handleBack={handleBack}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  phoneNumberError={phoneNumberError}
                  setPhoneNumberError={setPhoneNumberError}
                  governorate={governorate}
                  setGovernorate={setGovernorate}
                  governorateError={governorateError}
                  street={street}
                  setStreet={setStreet}
                  streetError={streetError}
                  judiciary={judiciary}
                  setJudiciary={setJudiciary}
                  judiciaryError={judiciaryError}
                  building={building}
                  setBuilding={setBuilding}
                  buildingError={buildingError}
                  town={town}
                  setTown={setTown}
                  townError={townError}
                  floor={floor}
                  setFloor={setFloor}
                  floorError={floorError}
                  POBox={POBox}
                  setPOBox={setPOBox}
                  POBoxError={POBoxError}
                />
              )}

              {/* {activeStep === 3 && (
                <Step4CheckBox
                  handleBack={handleBack}
                  handleNext={handleNextStep4}
                  step4check={step4check}
                  setStep4check={setStep4check}
                />
              )} */}

              {/* {activeStep === 4 && (
                <ApplicantInformation
                  handleBack={handleBack}
                  handleStepFive={handleStepFive}
                  applicantName={applicantName}
                  setApplicantName={setApplicantName}
                  applicantNameError={applicantNameError}
                  applicantMiddleName={applicantMiddleName}
                  setApplicantMiddleName={setApplicantMiddleName}
                  applicantMiddleNameError={applicantMiddleNameError}
                  applicantLastName={applicantLastName}
                  setApplicantLastName={setApplicantLastName}
                  applicantLastNameError={applicantLastNameError}
                  applicantCardId={applicantCardId}
                  setApplicantCardId={setApplicantCardId}
                  applicantCardIdError={applicantCardIdError}
                />
              )} */}

              {activeStep === 3 && (
                <AttachmentStep
                  step4check={step4check}
                  checked1={checked1}
                  checked2={checked2}
                  handleBack={handleBackStep4}
                  handleAttachmentStep={handleAttachmentStep}
                  personConcernedIdentity={personConcernedIdentity}
                  setPersonConcernedIdentity={setPersonConcernedIdentity}
                  personConcernedIdentityError={personConcernedIdentityError}
                  setPersonConcernedIdentityName={
                    setPersonConcernedIdentityName
                  }
                  applicantIdentity={applicantIdentity}
                  setApplicantIdentity={setApplicantIdentity}
                  applicantIdentityError={applicantIdentityError}
                  setApplicantIdentityName={setApplicantIdentityName}
                  authorizationPhoto={authorizationPhoto}
                  setAuthorizationPhoto={setAuthorizationPhoto}
                  authorizationPhotoError={authorizationPhotoError}
                  setAuthorizationPhotoName={setAuthorizationPhotoName}
                  commercialPhoto={commercialPhoto}
                  setCommercialPhoto={setCommercialPhoto}
                  commercialPhotoError={commercialPhotoError}
                  setCommercialPhotoName={setCommercialPhotoName}
                  commercialRegisterPhoto={commercialRegisterPhoto}
                  setCommercialRegisterPhoto={setCommercialRegisterPhoto}
                  commercialRegisterPhotoError={commercialRegisterPhotoError}
                  setCommercialRegisterPhotoName={
                    setCommercialRegisterPhotoName
                  }
                  //
                  IdentitiesOfHeirs={IdentitiesOfHeirs}
                  setIdentitiesOfHeirs={setIdentitiesOfHeirs}
                  IdentitiesOfHeirsError={IdentitiesOfHeirsError}
                  setIdentitiesOfHeirsName={setIdentitiesOfHeirsName}
                  //
                  InventoryOfInheritance={InventoryOfInheritance}
                  setInventoryOfInheritance={setInventoryOfInheritance}
                  InventoryOfInheritanceError={InventoryOfInheritanceError}
                  setInventoryOfInheritanceName={setInventoryOfInheritanceName}
                  //
                  JudicialRulings={JudicialRulings}
                  setJudicialRulings={setJudicialRulings}
                  JudicialRulingsError={JudicialRulingsError}
                  setJudicialRulingsName={setJudicialRulingsName}
                  //
                  OwnerIdentityBack={OwnerIdentityBack}
                  setOwnerIdentityBack={setOwnerIdentityBack}
                  OwnerIdentityBackError={OwnerIdentityBackError}
                  setOwnerIdentityBackName={setOwnerIdentityBackName}
                  //
                  AuthorizedSignatoryIdentity={AuthorizedSignatoryIdentity}
                  setAuthorizedSignatoryIdentity={
                    setAuthorizedSignatoryIdentity
                  }
                  AuthorizedSignatoryIdentityError={
                    AuthorizedSignatoryIdentityError
                  }
                  setAuthorizedSignatoryIdentityName={
                    setAuthorizedSignatoryIdentityName
                  }
                />
              )}

              {activeStep === 4 && (
                <RegistrationInfosStep
                  loadingRegistration={loadingRegistration}
                  handleBack={handleBack}
                  handleNext={handleRegistrationInfo}
                  email={email}
                  setEmail={setEmail}
                  emailError={emailError}
                  registrationPhoneNumber={registrationPhoneNumber}
                  setRegistrationPhoneNumber={setRegistrationPhoneNumber}
                  registrationPhoneNumberError={registrationPhoneNumberError}
                  setRegistrationPhoneNumberError={
                    setRegistrationPhoneNumberError
                  }
                  password={password}
                  setPassword={setPassword}
                  passwordError={passwordErrorRef.current}
                  setPasswordError={setPasswordError}
                  passwordConfirmation={passwordConfirmation}
                  setPasswordConfirmation={setPasswordConfirmation}
                  passwordConfirmationError={passwordConfirmationError}
                  setPasswordConfirmationError={setPasswordConfirmationError}
                  // captcha
                  setCaptchaValue={setCaptchaValue}
                  setCaptchaExpired={setCaptchaExpired}
                />
              )}

              {activeStep === 5 && (
                <FinalStep
                  handleBack={handleBack}
                  phoneLabelValue={registrationPhoneNumber}
                  emailLabelValue={email}
                  passWord={password}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      </Container>
    </Box>
  );
};

export default RegisterPage;
