import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PrettyStyle from "../pretty-style";
import { getPicklists } from "../../../../services/picklists.services";
import { findElementId } from "../../../../helpers/dynamicFormFunctions";
import { BeatLoader } from "react-spinners";
import {
  checkElementExistInFirstStep,
  findElementDefaultValueByGuid,
  findIdByGuid,
} from "../../../../helpers/dynamic-form/functionsOnElements";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
const DropdownListComponent = ({
  Name,
  Id,
  DefaultValue,
  MultipleValues,
  Placeholder,
  PicklistStyle,
  PicklistId,
  PicklistFilterType,
  PicklistFilterFieldGuid,
  PicklistFilterParentOptionId,
  PicklistMultipleSelection,
  Guid,
  Mandatory,
  checkConditionsChange,
  checkClickAble,
  checkFieldCorrection,
  data,
  columnSize,
  activeStep,
  sectorId,
  subsectoreId,
  PicklistFilterTag,
  PicklistFieldFilterTag,
  dynamicFormId,
  lockAllFieldsExceptAttachments,
}) => {
  const [allPicklists, setAllPicklists] = useState();
  const [listData, setListData] = useState();
  const [prettyStyleData, setPrettyStyleData] = useState();
  const [fieldValue, setFieldValue] = useState();
  const [valueToFilterOn, setValueToFilterOn] = useState();
  const isMobileView = useMediaQuery({ query: "(max-width: 576px)" });
  // UI/UX
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    const response = await getPicklists();
    setTimeout(() => {
      data?.map((step) => {
        step?.DynamicFormSections?.map((section) => {
          section.DynamicFormFields.map((field) => {
            checkConditionsChange(field.Guid);
          });
        });
      });
    }, 1000);
    return response;
  };
  const prettyStyleDataFunction = (data) => {
    let arr = [];
    data.map((picklist) => {
      if (picklist?.Id === PicklistId)
        picklist.Options?.map((opt) => {
          console.log("opt");
          console.log(opt);
          opt.Tags.map((tag) => {
            if (tag.TypeId == 8) {
              arr.push({ Id: opt.Id, Name: opt.Name, Img: tag.Value });
            }
          });
        });
    });
    setPrettyStyleData(arr);
  };
  const theNewDataSelector = async (dataList, fromListen = false) => {
    setLoading(true);
    let newData = [];
    let id = findElementId(data, PicklistFilterFieldGuid);
    const val = document.getElementById(id)?.value;
    if (val !== undefined) {
      dataList?.forEach((picklist) => {
        if (picklist?.Id === PicklistId)
          picklist?.Options?.forEach((item) => {
            if (item?.ParentId == val) newData.push(item);
          });
      });
      setListData(newData);
      checkClickAble(data);
    }
    if (!fromListen) {
      newData = [];
      let defaultValue = findElementDefaultValueByGuid(
        data,
        PicklistFilterFieldGuid
      );
      dataList?.forEach((picklist) => {
        if (picklist?.Id === PicklistId)
          picklist?.Options?.forEach((item) => {
            if (item?.ParentId == defaultValue?.Value) newData.push(item);
          });
      });
      setListData(newData);
      setTimeout(() => {
        checkClickAble(data, true);
      }, 800);
    }
    if (PicklistStyle === "PrettyStyle") {
      // prettyStyleDataFunction(response.data);
      prettyStyleDataFunction(dataList);
    }
    setLoading(false);
  };

  // comment title : custom option rendering in forms: 26 & 28
  // 1. create state to check if  غاز النفط المسيل is selected
  const [
    IsNaw3AlSahrejCustomConditionApplied,
    setIsNaw3AlSahrejCustomConditionApplied,
  ] = useState(false);
  // comment title : custom option rendering in forms: 26 & 28
  // 2. add event listener on the field نوع الصهريج
  const ele = document.getElementsByName("Type");

  if (ele !== null) {
    ele[0]?.addEventListener("input", async function (event) {
      // 2.1  غاز النفط المسيل option is selected
      setIsNaw3AlSahrejCustomConditionApplied(true);
    });
    ele[1]?.addEventListener("input", async function (event) {
      // 2.2  وقود option is selected
      setIsNaw3AlSahrejCustomConditionApplied(false);
    });
  }
  // check if it's selected earlier
  if (Name === "Region") {
    if (ele[0]?.checked);
    setTimeout(() => {
      setIsNaw3AlSahrejCustomConditionApplied(ele[0]?.checked);
    }, 500);
  }
  // use effects section
  useEffect(() => {
    let eleId = findElementId(data, PicklistFilterFieldGuid);
    const element = document.getElementById(eleId);
    if (element !== null)
      element.addEventListener("change", async function (event) {
        setLoading(true);
        const response = await getData();
        if (response.status === 200) {
          theNewDataSelector(response?.data, true);
        }
      });
    // Clean up the event listener when component unmounts
    return () => {
      element?.removeEventListener("change", async function (event) {
        setLoading(true);
        const response = await getData();
        if (response.status === 200) {
          theNewDataSelector(response?.data, true);
        }
      });
    };
  }, [activeStep]);
  useEffect(() => {
    const elementId = findIdByGuid(data, PicklistFieldFilterTag);
    setValueToFilterOn(elementId.id);
  }, [activeStep]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getData();
      if (response.status === 200) {
        setAllPicklists(response.data);
        if (PicklistStyle === "PrettyStyle") {
          prettyStyleDataFunction(response.data);
        }
        theNewDataSelector(response.data);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  // get label for extra list option in dynamic form 20
  // form: اصحاب المولدات
  // sector id: 1037
  // sub sector id: 2748

  const getCustomOptionLabelInComplaint = () => {
    let optionLabel = "";
    allPicklists?.map((item, index) => {
      if (item?.Id == 114) {
        item?.Options?.map((option, index) => {
          if (option?.Id == 10606) {
            optionLabel = option.Name;
          }
        });
      }
    });
    return optionLabel;
  };

  if (PicklistStyle === "DropDownList") {
    return (
      <div className="custom-select d-flex">
        {false ? (
          <div className="d-flex">
            <div className="picklist-loader">
              <div
                class="spinner-border spinner-border-sm text-success"
                role="status"
              ></div>
            </div>
            <select
              name={Name}
              id={Id}
              className="p-2 mb-0 w-100"
              disabled={true}
            >
              <option value="">{Placeholder}</option>
            </select>
          </div>
        ) : (
          <>
            {loading && (
              <div className="picklist-loader-dropdown pt-2 pe-2">
                <div
                  class="spinner-border spinner-border-sm text-success"
                  role="status"
                ></div>
              </div>
            )}
            <select
              name={Name}
              id={Id}
              className={
                loading
                  ? "custome-loading-select p-2 mb-0 w-100"
                  : "p-2 mb-0 w-100"
              }
              onChange={() => {
                checkConditionsChange(Guid);
                checkClickAble(data);
                checkFieldCorrection(Id);
              }}
              onBlur={() => checkFieldCorrection(Id)}
              required={Mandatory}
              disabled={loading || lockAllFieldsExceptAttachments}
            >
              {loading && <option selected value=""></option>}
              <option value="">{Placeholder}</option>
              {PicklistFilterType === "FilterBaseOnFieldValue"
                ? listData?.map((opt) =>
                    opt.Id == DefaultValue ||
                    opt.Id == sectorId ||
                    opt.Id == subsectoreId ? (
                      <option value={opt.Id} selected>
                        {opt?.Name}
                      </option>
                    ) : (
                      <option value={opt.Id}>{opt?.Name}</option>
                    )
                  )
                : PicklistFilterType === "FilterBaseOnFieldTag"
                ? PicklistFilterFieldGuid != null
                  ? listData?.map((opt) =>
                      opt?.Tags?.map((tag) =>
                        tag?.TypeId == PicklistFilterTag &&
                        tag?.Value.includes(
                          document.getElementById(valueToFilterOn)?.value
                        ) ? (
                          opt.Id == DefaultValue ||
                          opt.Id == sectorId ||
                          opt.Id == subsectoreId ? (
                            <option value={opt.Id} selected>
                              {opt?.Name}
                            </option>
                          ) : (
                            <option value={opt.Id}>{opt?.Name}</option>
                          )
                        ) : (
                          <></>
                        )
                      )
                    )
                  : allPicklists?.map(
                      (picklist) =>
                        picklist?.Id === PicklistId &&
                        picklist?.Options?.map((opt) =>
                          opt?.Tags?.map((tag) =>
                            tag?.TypeId == PicklistFilterTag &&
                            tag?.Value.includes(
                              document.getElementById(valueToFilterOn)?.value
                            ) ? (
                              opt.Id == DefaultValue ||
                              opt.Id == sectorId ||
                              opt.Id == subsectoreId ? (
                                <option value={opt.Id} selected>
                                  {opt?.Name}
                                </option>
                              ) : (
                                <option value={opt.Id}>{opt?.Name}</option>
                              )
                            ) : (
                              <></>
                            )
                          )
                        )
                    )
                : allPicklists?.map(
                    (picklist) =>
                      picklist?.Id === PicklistId &&
                      picklist?.Options?.map((opt) =>
                        (dynamicFormId == 26 || dynamicFormId == 28) &&
                        IsNaw3AlSahrejCustomConditionApplied ? (
                          // comment title : custom option rendering in forms: 26 & 28
                          // 3. if the value of new state "" is true we displaying the 2 options only otherwise we display all options
                          (opt.Id == "1101" || opt.Id == "1104") &&
                          (opt.Id == DefaultValue ||
                          opt.Id == sectorId ||
                          opt.Id == subsectoreId ? (
                            <option value={opt.Id} selected>
                              {opt?.Name}
                            </option>
                          ) : (
                            <option value={opt.Id}>{opt?.Name}</option>
                          ))
                        ) : opt.Id == DefaultValue ||
                          opt.Id == sectorId ||
                          opt.Id == subsectoreId ? (
                          <option value={opt.Id} selected>
                            {opt?.Name}
                          </option>
                        ) : (
                          <option value={opt.Id}>{opt?.Name}</option>
                        )
                      )
                  )}
              {dynamicFormId == 20 &&
                sectorId == 1037 &&
                subsectoreId == 2748 &&
                Id == 129 && (
                  <option value="10606">
                    {getCustomOptionLabelInComplaint()}
                    {/* شكوى متعلقة بعدادات غير دقيقة */}
                  </option>
                )}
            </select>
          </>
        )}
      </div>
    );
  } else if (PicklistStyle === "CheckboxList") {
    return (
      <div>
        {loading ? (
          <div className="picklist-loader">
            <BeatLoader color="var(--green)" />
          </div>
        ) : PicklistMultipleSelection ? (
          <div className="d-flex flex-column">
            {PicklistFilterType === "FilterBaseOnFieldValue"
              ? listData?.map((opt) => (
                  <FormControlLabel
                    value={opt.Id}
                    control={
                      <Checkbox
                        disabled={lockAllFieldsExceptAttachments}
                        onChange={() => {
                          checkConditionsChange(Guid);
                          checkClickAble(data);
                          checkFieldCorrection(Id);
                        }}
                        onBlur={() => checkFieldCorrection(Id)}
                        defaultChecked={MultipleValues?.includes(
                          (opt?.Id).toString()
                        )}
                      />
                    }
                    label={opt?.Name}
                    sx={{
                      "& .MuiTypography-root": {
                        color: "#194522",
                        textAlign: "center",
                        fontFamily: "Cairo",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "188.9%" /* 34.002px */,
                      },
                    }}
                    id={Id}
                    name={Name}
                  />
                ))
              : PicklistFilterType === "FilterBaseOnFieldValue"
              ? allPicklists?.map(
                  (picklist) =>
                    picklist?.Id === PicklistId &&
                    picklist?.Options?.map(
                      (opt) =>
                        opt.ParentId === PicklistFilterParentOptionId && (
                          <FormControlLabel
                            value={opt.Id}
                            control={
                              <Checkbox
                                disabled={lockAllFieldsExceptAttachments}
                                onChange={() => {
                                  checkConditionsChange(Guid);
                                  checkClickAble(data);
                                  checkFieldCorrection(Id);
                                }}
                                onBlur={() => checkFieldCorrection(Id)}
                                defaultChecked={MultipleValues?.includes(
                                  (opt?.Id).toString()
                                )}
                              />
                            }
                            label={opt?.Name}
                            sx={{
                              "& .MuiTypography-root": {
                                color: "#194522",
                                textAlign: "center",
                                fontFamily: "Cairo",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "188.9%" /* 34.002px */,
                              },
                            }}
                            id={Id}
                            name={Name}
                            // defaultChecked={opt.Id == DefaultValue}
                          />
                        )
                    )
                )
              : allPicklists?.map(
                  (picklist) =>
                    picklist?.Id === PicklistId &&
                    picklist?.Options?.map((opt) => (
                      <FormControlLabel
                        value={opt.Id}
                        control={
                          <Checkbox
                            disabled={lockAllFieldsExceptAttachments}
                            // checked={opt.Id == DefaultValue}
                            onChange={() => {
                              checkConditionsChange(Guid);
                              checkClickAble(data);
                              checkFieldCorrection(Id);
                            }}
                            onBlur={() => checkFieldCorrection(Id)}
                            defaultChecked={MultipleValues?.includes(
                              (opt?.Id).toString()
                            )}
                          />
                        }
                        label={opt?.Name}
                        sx={{
                          "& .MuiTypography-root": {
                            color: "#194522",
                            textAlign: "center",
                            fontFamily: "Cairo",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "188.9%" /* 34.002px */,
                          },
                        }}
                        id={Id}
                        name={Name}

                        // defaultChecked={opt.Id == DefaultValue}
                      />
                    ))
                )}
          </div>
        ) : (
          <RadioGroup
            className={
              PicklistId === "103"
                ? "d-flex flex-column customRadioButtons"
                : isMobileView
                ? "d-flex flex-column customRadioButtons"
                : "d-flex flex-row customRadioButtons"
            }
            aria-label="CheckboxList"
            name={Name}
            // value={value}
            id={Id}
            onChange={() => {
              checkConditionsChange(Guid);
              checkClickAble(data);
              checkFieldCorrection(Id);
            }}
            onBlur={() => checkFieldCorrection(Id)}
            defaultValue={DefaultValue}
          >
            {PicklistFilterType === "FilterBaseOnFieldValue"
              ? listData?.map((opt) => (
                  <FormControlLabel
                    className="me-3"
                    control={
                      <Radio disabled={lockAllFieldsExceptAttachments} />
                    }
                    labelPlacement="end"
                    label={opt?.Name}
                    name={Name}
                    id={Id}
                    value={opt.Id}
                    // checked={opt.Id == DefaultValue}
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        marginRight: "0rem",
                        marginLeft: "1rem",
                      },
                    }}
                  />
                ))
              : PicklistFilterType === "FilterBaseOnFieldValue"
              ? allPicklists?.map(
                  (picklist) =>
                    picklist?.Id === PicklistId &&
                    picklist?.Options?.map(
                      (opt) =>
                        opt.ParentId === PicklistFilterParentOptionId && (
                          <FormControlLabel
                            control={
                              <Radio
                                disabled={lockAllFieldsExceptAttachments}
                              />
                            }
                            labelPlacement="end"
                            label={opt?.Name}
                            name={Name}
                            id={Id}
                            value={opt.Id}
                            // checked={opt.Id == DefaultValue}
                            sx={{
                              "&.MuiFormControlLabel-root": {
                                marginRight: "0rem",
                                marginLeft: "1rem",
                              },
                            }}
                          />
                        )
                    )
                )
              : allPicklists?.map(
                  (picklist) =>
                    picklist?.Id === PicklistId &&
                    picklist?.Options?.map((opt) => (
                      <FormControlLabel
                        control={
                          <Radio disabled={lockAllFieldsExceptAttachments} />
                        }
                        labelPlacement="end"
                        label={opt?.Name}
                        name={Name}
                        id={Id}
                        value={opt.Id}
                        // checked={opt.Id == DefaultValue}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            marginRight: 0,
                            marginLeft: "1rem",
                          },
                        }}
                      />
                    ))
                )}
          </RadioGroup>
        )}
      </div>
    );
  } else {
    return (
      <div>
        {loading ? (
          <div className="picklist-loader">
            <BeatLoader color="var(--green)" />
          </div>
        ) : (
          <PrettyStyle
            Id={Id}
            Name={Name}
            Placeholder={Placeholder}
            DefaultValue={DefaultValue}
            PicklistStyle={PicklistStyle}
            PicklistId={PicklistId}
            PicklistFilterType={PicklistFilterType}
            PicklistFilterFieldGuid={PicklistFilterFieldGuid}
            PicklistFilterParentOptionId={PicklistFilterParentOptionId}
            Guid={Guid}
            allPicklists={allPicklists}
            listData={listData}
            prettyStyleData={prettyStyleData}
            checkConditionsChange={checkConditionsChange}
            checkClickAble={checkClickAble}
            checkFieldCorrection={checkFieldCorrection}
            data={data}
            columnSize={columnSize}
            lockAllFieldsExceptAttachments={lockAllFieldsExceptAttachments}
          />
        )}
      </div>
    );
  }
};

export default DropdownListComponent;
// comment title : custom option rendering in forms: 26 & 28
// طلب كيل صهريج لتجديد الشهادة السنوية id: 26 , CPD-S-006
// طلب كيل صهريج لإجراء تصليحات id: 28 , CPD-S-008
// ******************
// request story:
// in this forms we are displaying only 2 options in DDL Field المحافظة وفقاً لمحل الإقامة المذكور على رخصة سير المركبة الآلية*
//   جبل لبنان and بيروت if the user choosed نوع الصهريج: غاز النفط المسيل
// ******************
// steps to achieve
// 1. create state to check if  غاز النفط المسيل is selected
// 2. add event listener on the field نوع الصهريج
//    ->  2.1  غاز النفط المسيل option is selected
//    ->  2.2  وقود option is selected
// 3. if the value of new state "" is true we displaying the 2 options only otherwise we display all options
// check if it's selected earlier -> to check if it's draft and selected
