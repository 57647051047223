import React, { useEffect, useState } from "react";
import { getAccessTokenCookie } from "../../utils/cookie";
import { useLocation, useParams } from "react-router-dom";
import environment from "../../environment";
import axios from "axios";
import { RefreshTokenFunction } from "../../services/refreshToken.services";
import { handleError } from "../../services/error.service";
import Loader from "../../components/loader";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import useStateRef from "react-usestateref";
import classes from "./styles.module.css";
import CircleIcon from "../dashboard/components/CircleIcon";
import { getProductsGrid } from "../../services/dynamicForm.services";
import GridTable from "../../components/dynamic-form/grid-table";
const ReviewOrder = () => {
  const [data, setData, dataRef] = useStateRef();
  const [transactionProducts, setTransactionProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = getAccessTokenCookie();
  let { formId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [transactionId, setTransactionId] = useState(
    queryParams.get("transactionId")
  );
  const getProductsGridData = async () => {
    const response = await getProductsGrid(formId);
    if (response?.status == 200) {
      const newArray = response?.data?.map((item, index) => {
        return { ...item, Id: index };
      });
      setTransactionProducts(newArray);
    }
  };
  useEffect(() => {
    if (formId) {
      setIsLoading(true);
      axios
        .get(`${environment.API_URL}/${environment.formFields}${formId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 401) {
            RefreshTokenFunction();
          }
          if (response.status === 200 || response.status === 204) {
            setData(response.data);
            if (formId) getProductsGridData();
            setIsLoading(false);
          }
        })
        .catch((error) => {
          handleError(error?.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [formId]);

  // const getType = (filePath) => {
  //   return filePath?.split(".").pop();
  // };

  // const openPdf = (filePath) => {
  //   window.open(filePath, "_blank");
  // };
  return isLoading ? (
    <Loader />
  ) : (
    <Container
      maxWidth="lg"
      sx={{ position: "relative", zIndex: 999, marginTop: "2rem" }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} textAlign={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            <Typography className={classes.title}>
              {dataRef?.current?.DynamicForm?.DisplayName}
            </Typography>
          </Stack>
        </Grid>
        {dataRef.current?.Steps?.map((item) =>
          item?.DynamicFormSections?.map((sections) =>
            sections?.DynamicFormFields?.map((field) =>
              field.JustView ? null : field.Value ||
                field?.FieldType === "Grid" ? (
                <Grid item xs={12} key={field.Id}>
                  {field.FieldType === "Attachment" ||
                  field.FieldType === "OtherAttachments" ? (
                    <Stack className={classes.fieldStyle}>
                      <Typography variant="h6" className={classes.fieldName}>
                        {field.Label}
                      </Typography>
                      <a
                        href={`${environment.IMAGE_URL}${field.Value}`}
                        download
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          className={classes.fieldValue}
                          sx={{
                            color: "#4fae62",
                            cursor: "pointer",
                            width: "max-content",
                          }}
                        >
                          تحميل الملف المرفق (
                          {field?.Value?.substring(
                            field?.Value?.lastIndexOf("/") + 1,
                            field?.Value?.indexOf("_")
                          )}
                          {field?.Value?.substring(
                            field?.Value?.lastIndexOf(".")
                          )}
                          )
                        </Typography>
                      </a>
                    </Stack>
                  ) : field?.FieldType === "Grid" ? (
                    transactionProducts?.length > 0 ? (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        className={classes.fieldStyle}
                      >
                        <GridTable
                          transactionProducts={transactionProducts}
                          setTransactionProducts={setTransactionProducts}
                          viewOnly={true}
                        />
                      </Stack>
                    ) : null
                  ) : field.FieldType === "PhoneNumber" ? (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      className={classes.fieldStyle}
                    >
                      <Typography variant="h6" className={classes.fieldName}>
                        {field.Label}
                      </Typography>
                      <p style={{ margin: "0 0.5rem" }}>:</p>
                      <Typography
                        variant="body1"
                        className={classes.fieldValue}
                        sx={{ direction: "ltr" }}
                      >
                        {field.Value.replace(/_/g, "")}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      className={classes.fieldStyle}
                    >
                      <Typography variant="h6" className={classes.fieldName}>
                        {field.Label}
                      </Typography>
                      <p style={{ margin: "0 0.5rem" }}>:</p>
                      <Typography
                        variant="body1"
                        className={classes.fieldValue}
                      >
                        {field.Value === "False"
                          ? "لا"
                          : field.Value === "True"
                          ? "نعم"
                          : field.Value}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              ) : null
            )
          )
        )}
      </Grid>
    </Container>
  );
};

export default ReviewOrder;
