import React, { useEffect, useState } from "react";
import { StyledModal } from "./styles";
import { Formik, useFormik } from "formik";

import * as Yup from "yup";
import { Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FourMpRounded, UpdateSharp } from "@mui/icons-material";
import {
  findElementObjectById,
  findGuidById,
} from "../../../helpers/dynamic-form/functionsOnElements";
import { useMediaQuery } from "react-responsive";
const AddNewProduct = (props) => {
  const {
    data,
    setData,
    currentId,
    setCurrentId,
    productTypesOptions,
    originCountriesOptions,
    PacketsTypesOptions,
    isEdit,
    setIsEdit,
    editRowValues,
    editRowIndex,
    // related to form data functionality
    formData,
    applyChangesOnField,
    checkClickAble,
  } = props;
  //
  const isTabletView = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });
  //
  const [firstValidateVisibility, setFirstValidateVisibility] = useState(false);
  const [ValidateOnChange, setValidateOnChange] = useState(false);
  const [existErrorMessage, setExistErrorMessage] = useState(false);
  const [ProductType, setProductType] = useState("");
  const [PacketsType, setPacketsType] = useState("");
  const [productRelatedField, setProductRelatedField] = useState("");
  const [OriginCountry, setOriginCountry] = useState("");
  // new field

  const handleProductTypeChange = (newValue) => {
    form.setFieldValue("ProductTypeId", newValue);
    form.validateForm();
    setExistErrorMessage(false);
  };
  const handleHomeCountryChange = (newValue) => {
    form.setFieldValue("OriginCountryId", newValue);
    form.validateForm();
  };
  const handlePacketsTypeChange = (newValue) => {
    form.setFieldValue("PacketsTypeId", newValue);
    form.validateForm();
  };
  // const handleOtherProductInputChange = (value, index) => {
  //   const newArray = [...otherProductTypeList];
  //   newArray[index] = value;
  //   setOtherProductTypeList(newArray);
  // };
  //  Form
  const validationSchema = Yup.object({
    // profileImage: Yup.string().required(t("Profile image is required")),
    ProductTypeId: Yup.string().required("نوع البضاعة مطلوب"),
    TradeName: Yup.string().required("الاسم التجاري مطلوب"),
    OriginCountryId: Yup.string().required("بلد المنشأ مطلوب"),
    PacketsNumber: Yup.string().required("عدد الطرود مطلوب"),
    PacketsTypeId: Yup.string().required("نوع الطرود مطلوب"),
    ProductType: Yup.string().when("ProductTypeId", {
      is: (ProductTypeId) => ProductTypeId == "11375",
      then: () => Yup.string().required("نوع البضاعة الأخرى مطلوب"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  // Conditionally require otherProductsType
  const isFieldsValid = () => {
    if (form.values.ProductTypeId == "11375") {
      return (
        form.values.ProductTypeId?.length !== 0 &&
        form.values.TradeName?.length !== 0 &&
        form.values.OriginCountryId?.length !== 0 &&
        form.values.PacketsNumber?.length !== 0 &&
        form.values.ProductType?.length !== 0 &&
        form.values.PacketsTypeId?.length !== 0
      );
    } else {
      return (
        form.values.ProductTypeId?.length !== 0 &&
        form.values.TradeName?.length !== 0 &&
        form.values.OriginCountryId?.length !== 0 &&
        form.values.PacketsNumber?.length !== 0 &&
        form.values.PacketsTypeId?.length !== 0
      );
    }
    //   form.errors.ProductTypeId?.length === undefined &&
    //   form.errors.TradeName?.length === undefined &&
    //   form.errors.OriginCountryId?.length === undefined &&
    //   form.errors.PacketsNumber?.length === undefined
  };
  const checkProductExistence = () => {
    return data?.findIndex(
      (item) =>
        item?.ProductTypeId === form.values.ProductTypeId &&
        form.values.ProductTypeId != "11375"
    );
  };
  const submitForm = async (values) => {
    const pass = isFieldsValid();
    if (pass) {
      let productObject = {
        Id: isEdit ? editRowIndex : currentId + 1,
        ProductTypeId: form.values.ProductTypeId,
        ProductType:
          form.values.ProductTypeId == "11375"
            ? form.values.ProductType
            : ProductType,
        productRelatedField: productRelatedField,
        TradeName: form.values.TradeName,
        OriginCountryId: form.values.OriginCountryId,
        OriginCountry: OriginCountry,
        PacketsNumber: form.values.PacketsNumber,
        PacketsTypeId: form.values.PacketsTypeId,
        PacketsType: PacketsType,
      };
      const isExist = checkProductExistence();

      if (isExist == -1 || (isEdit && data[isExist]?.Id == editRowIndex)) {
        if (isEdit) {
          const updatedData = [...data];
          const index = updatedData?.findIndex(
            (item) => item?.Id === editRowIndex
          );
          updatedData[index] = productObject;
          setData(updatedData);
          setIsEdit(false);
          props.onHide();
        } else {
          // const isExist = checkProductExistence();
          // if (isExist == -1) {
          setExistErrorMessage(false);
          props.onAdd(productObject);
          setCurrentId(currentId + 1);
          // sho related element
          if (productRelatedField) {
            const elementObj = findElementObjectById(
              formData,
              productRelatedField
            );
            const { StepGuid, SectionGuid, Field } = elementObj;
            applyChangesOnField(
              true,
              "VisibilityCondition",
              StepGuid,
              SectionGuid,
              Field.Guid,
              Field.Id
            );
            applyChangesOnField(
              true,
              "MandatoryCondition",
              StepGuid,
              SectionGuid,
              Field.Guid,
              Field.Id
            );
            checkClickAble(formData);
            setTimeout(() => {}, 5500);
          }
          setIsEdit(false);
          // hide the modal
          props.onHide();
        }
      } else {
        setExistErrorMessage(true);
      }
      setIsEdit(false);
    } else {
      setValidateOnChange(true);
      setFirstValidateVisibility(true);
      form.validateForm();
    }
  };
  const form = useFormik({
    isInitialValidP: false,
    validateOnBlur: true,
    validateOnChange: ValidateOnChange,
    onSubmit: (values) => {
      // submitForm(values);
    },
    validationSchema,
    initialValues: {
      // profileImage: {},
      ProductTypeId: "",
      TradeName: "",
      OriginCountryId: "",
      PacketsNumber: "",
      ProductType: "",
      PacketsTypeId: "",
    },
  });
  useEffect(() => {
    if (isEdit) {
      form.setFieldValue("ProductTypeId", editRowValues?.ProductTypeId);
      setProductType(editRowValues?.ProductType);
      form.setFieldValue("TradeName", editRowValues?.TradeName);
      form.setFieldValue("OriginCountryId", editRowValues?.OriginCountryId);
      setOriginCountry(editRowValues?.OriginCountry);
      form.setFieldValue("PacketsNumber", editRowValues?.PacketsNumber);
      form.setFieldValue("ProductType", editRowValues?.ProductType);
      form.setFieldValue("PacketsTypeId", editRowValues?.PacketsTypeId);
      setPacketsType(PacketsType);
      setFirstValidateVisibility(false);
    } else {
      form.setFieldValue("ProductTypeId", "");
      form.setFieldValue("TradeName", "");
      form.setFieldValue("OriginCountryId", "");
      form.setFieldValue("PacketsNumber", "");
      form.setFieldValue("ProductType", "");
      form.setFieldValue("PacketsTypeId", "");
      setOriginCountry("");
      setProductType("");
      setPacketsType("");
      setFirstValidateVisibility(false);
    }
    if (!props.show) {
      form.setFieldValue("ProductTypeId", "");
      form.setFieldValue("TradeName", "");
      form.setFieldValue("OriginCountryId", "");
      form.setFieldValue("PacketsNumber", "");
      form.setFieldValue("ProductType", "");
      form.setFieldValue("PacketsTypeId", "");
      setOriginCountry("");
      setProductType("");
      setPacketsType("");
      setFirstValidateVisibility(false);
    }
  }, [props.show]);
  return (
    <StyledModal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      direction={"rtl"}
    >
      <StyledModal.Header>
        <h3>تفاصيل نوع البضاعة</h3>
      </StyledModal.Header>
      <StyledModal.Body>
        <Formik>
          <Form
            onSubmit={form.handleSubmit}
            className="w-100 row justify-content-start"
          >
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"ProductTypeId"} required className="">
                  نوع البضاعة
                  <span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <Autocomplete
                  options={productTypesOptions}
                  getOptionLabel={(option) => option.Name}
                  value={
                    productTypesOptions.find(
                      (item) => item.Id == form.values.ProductTypeId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleProductTypeChange(newValue?.Id);
                    if (newValue) {
                      setProductType(newValue.Name);
                      setProductRelatedField(newValue.Tags[0]?.Value);
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.Id === value.Id
                  }
                  noOptionsText="لا توجد خيارات"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="اختر نوع المنتج"
                      error={
                        Boolean(form.errors.ProductTypeId) &&
                        ValidateOnChange &&
                        firstValidateVisibility
                      }
                      // helperText={
                      //   Boolean(form.errors.ProductTypeId) &&
                      //   ValidateOnChange &&
                      //   form.errors.ProductTypeId
                      // }
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "4px", // Adjust the padding to reduce the input height
                          borderRadius: "12px",
                          background: "rgb(237, 239, 240)",
                        },
                        "& label": {
                          right: 0, // Align label to the right
                          left: "unset",
                          textAlign: "right", // Align the text of the label to the right
                          direction: "rtl", // Set text direction to right-to-left for Arabic
                        },
                        "& .MuiOutlinedInput-root": {
                          minHeight: "40px", // You can further reduce this if necessary
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            existErrorMessage ||
                            (Boolean(form.errors.ProductTypeId) &&
                              ValidateOnChange &&
                              firstValidateVisibility)
                              ? "1px solid red"
                              : "none",
                          outline: "none",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0.7rem",
                      borderRadius: "12px",
                      background: "rgb(237, 239, 240)",
                    },
                    "& .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline":
                      {
                        border:
                          existErrorMessage ||
                          (Boolean(form.errors.ProductTypeId) &&
                            ValidateOnChange &&
                            firstValidateVisibility)
                            ? "1px solid red"
                            : "none",
                      },
                  }}
                />
              </Col>
            </FormGroup>
            {form.values.ProductTypeId == "11375" && (
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={`ProductType`} required className="">
                    يرجى تحديد نوع البضاعة<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <TextField
                    //   id={id}
                    name={"ProductType"}
                    type={"text"}
                    // defaultValue={DefaultValue}
                    value={form.values.ProductType}
                    required={true}
                    onChange={form.handleChange}
                    variant="outlined"
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            Boolean(form.errors.ProductType) &&
                            ValidateOnChange &&
                            firstValidateVisibility
                              ? "1px solid red"
                              : "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Col>
              </FormGroup>
            )}
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"TradeName"} required className="">
                  الاسم التجاري<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  //   id={id}
                  name={"TradeName"}
                  type={"text"}
                  // defaultValue={DefaultValue}
                  value={form.values.TradeName}
                  required={true}
                  onChange={form.handleChange}
                  //   helperText={
                  //     Boolean(form.errors.TradeName) &&
                  //     ValidateOnChange &&
                  //     form.errors.TradeName
                  //   }
                  variant="outlined"
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                      "& .MuiFormHelperText-root": {
                        color: "#d1202f",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      outline: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&:hover, &:focus": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          Boolean(form.errors.TradeName) &&
                          ValidateOnChange &&
                          firstValidateVisibility
                            ? "1px solid red"
                            : "none",
                        outline: "none",
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"OriginCountryId"} required className="">
                  بلد المنشأ<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <Autocomplete
                  options={originCountriesOptions}
                  getOptionLabel={(option) => option?.Name || ""} // Ensure the label is properly displayed
                  value={
                    originCountriesOptions.find(
                      (item) => item.Id == form.values.OriginCountryId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleHomeCountryChange(newValue?.Id);
                    // handleHomeCountryChange(event);
                    if (newValue) {
                      setOriginCountry(newValue.Name);
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.Id === value?.Id
                  } // Ensure proper comparison
                  noOptionsText="لا توجد خيارات" // Custom text for no options available in Arabic
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        Boolean(form.errors.OriginCountryId) &&
                        ValidateOnChange &&
                        firstValidateVisibility
                      }
                      // helperText={
                      //   Boolean(form.errors.OriginCountryId) &&
                      //   ValidateOnChange &&
                      //   form.errors.OriginCountryId
                      // }
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "4px", // Adjust the padding to reduce input height
                          borderRadius: "12px",
                          background: "rgb(237, 239, 240)",
                        },
                        "& label": {
                          right: 0, // Align label to the right
                          left: "unset",
                          textAlign: "right", // Align text to the right for Arabic
                          direction: "rtl", // Set right-to-left text direction for Arabic
                        },
                        "& .MuiOutlinedInput-root": {
                          minHeight: "40px", // Optional: further reduce input height
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            Boolean(form.errors.OriginCountryId) &&
                            ValidateOnChange &&
                            firstValidateVisibility
                              ? "1px solid red"
                              : "none",
                          outline: "none",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0.7rem",
                      borderRadius: "12px",
                      background: "rgb(237, 239, 240)",
                    },
                    "& .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline":
                      {
                        border:
                          Boolean(form.errors.OriginCountryId) &&
                          ValidateOnChange &&
                          firstValidateVisibility
                            ? "1px solid red"
                            : "none",
                      },
                  }}
                />

                {/* <Select
                  value={form.values.OriginCountryId}
                  onChange={handleHomeCountryChange}
                  //   helperText={
                  //     Boolean(form.errors.OriginCountryId) &&
                  //     ValidateOnChange &&
                  //     form.errors.OriginCountryId
                  //   }
                  sx={{
                    borderRadius: "12px",
                    "&.MuiInputBase-root": {
                      width: "100%",
                    },
                    "& .MuiSelect-select": {
                      background: "rgb(237, 239, 240)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      // "&:hover": {
                      //   backgroundColor: "#67d57d",
                      // },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        Boolean(form.errors.OriginCountryId) &&
                        ValidateOnChange &&
                        firstValidateVisibility
                          ? "1px solid red"
                          : "none",
                      outline: "none",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "var(--second-linear-green)",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "var(--first-linear-green)",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "var(--med-linear-green)",
                        },
                      },
                    },
                  }}
                >
                  {originCountriesOptions?.map((item) => (
                    <MenuItem
                      value={item.Id}
                      key={item.Id}
                      onClick={() => setOriginCountry(item?.Name)}
                    >
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select> */}
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"PacketsNumber"} required className="">
                  عدد الطرود<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  //   id={id}
                  value={form.values.PacketsNumber}
                  name={"PacketsNumber"}
                  type={"number"}
                  //   defaultValue={DefaultValue}
                  required={true}
                  onChange={form.handleChange}
                  variant="outlined"
                  inputProps={{ min: 0 }} // Prevent negative numbers
                  //   helperText={
                  //     Boolean(form.errors.PacketsNumber) &&
                  //     ValidateOnChange &&
                  //     form.errors.PacketsNumber
                  //   }
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                      "& .MuiFormHelperText-root": {
                        color: "#d1202f",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      outline: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&:hover, &:focus": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border:
                          Boolean(form.errors.PacketsNumber) &&
                          ValidateOnChange &&
                          firstValidateVisibility
                            ? "1px solid red"
                            : "none",
                        outline: "none",
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"PacketsTypeId"} required className="">
                  نوع الطرود
                  <span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <Autocomplete
                  options={PacketsTypesOptions}
                  getOptionLabel={(option) => option.Name}
                  value={
                    PacketsTypesOptions.find(
                      (item) => item.Id == form.values.PacketsTypeId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handlePacketsTypeChange(newValue?.Id);
                    if (newValue) {
                      setPacketsType(newValue.Name);
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.Id === value.Id
                  }
                  noOptionsText="لا توجد خيارات"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="اختر نوع المنتج"
                      error={
                        Boolean(form.errors.PacketsTypeId) &&
                        ValidateOnChange &&
                        firstValidateVisibility
                      }
                      // helperText={
                      //   Boolean(form.errors.PacketsTypeId) &&
                      //   ValidateOnChange &&
                      //   form.errors.PacketsTypeId
                      // }
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "4px", // Adjust the padding to reduce the input height
                          borderRadius: "12px",
                          background: "rgb(237, 239, 240)",
                        },
                        "& label": {
                          right: 0, // Align label to the right
                          left: "unset",
                          textAlign: "right", // Align the text of the label to the right
                          direction: "rtl", // Set text direction to right-to-left for Arabic
                        },
                        "& .MuiOutlinedInput-root": {
                          minHeight: "40px", // You can further reduce this if necessary
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            Boolean(form.errors.PacketsTypeId) &&
                            ValidateOnChange &&
                            firstValidateVisibility
                              ? "1px solid red"
                              : "none",
                          outline: "none",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0.7rem",
                      borderRadius: "12px",
                      background: "rgb(237, 239, 240)",
                    },
                    "& .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline":
                      {
                        border:
                          Boolean(form.errors.PacketsTypeId) &&
                          ValidateOnChange &&
                          firstValidateVisibility
                            ? "1px solid red"
                            : "none",
                      },
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
        </Formik>
        <div className="text-center error-container">
          {existErrorMessage && (
            <p className="error-message text-red mt-2 mb-3">
              تم إدخال هذا النوع مسبقا
            </p>
          )}
        </div>
      </StyledModal.Body>
      <StyledModal.Footer>
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          onClick={submitForm}
          disabled={false}
        >
          {isEdit ? "تحديث البيانات" : "إضافة"}
        </Button>
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          onClick={() => {
            props.onHide();
            setIsEdit(false);
          }}
          disabled={false}
        >
          إلغاء
        </Button>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default AddNewProduct;
// [
//   {
//     ProductTypeId: 3225,
//     OriginCountryId: 10266,
//     PacketsNumber: 12345,
//     TradeName: "name",
//   },
//   {
//     ProductTypeId: 3227,
//     OriginCountryId: 10265,
//     PacketsNumber: 1234,
//     TradeName: "name",
//   },
// ];
